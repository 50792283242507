@import '../../assets/style/_variable';

.map {
  height: 500px;
  filter: grayscale(0.6);

  &__container {
    width: 100%;
    height: 100%;
  }
}

.map__header {
  margin-left: 10px;
  font-size: 1.2rem;
  color: $px-footer;
  vertical-align: middle;
}

.map__logo {
  width: 32px;
  height: 32px;
}

.map__hint {
  margin: 5px;
  width: 250px;
  border-radius: 4px;

  &p {
    font-size: 0.9rem;
    margin: 0;
  }
}

.map__hint_img {
  width: 100%;
}

.map__hint_phones {
  display: grid;
  grid-template-columns: 1fr;
}

.map__hint_link {
  padding: 2px 0;
  font-size: 0.8rem;
  font-weight: 700;
  text-decoration: none;
  color: #000;

  &:hover {
    color: $px-yellow;
  }
}

.map__hint_social {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, auto));
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .map {
    height: 390px;
  }
}