@import '../../../assets/style/_variable';

.special-block {
  background-image: url('../../../assets/images/home/sliderHome_1.jpg');
  background-attachment: fixed;
  background-size: cover;

  &__bg {
    background-color: $px-background-main;
    padding-bottom: 90px;
  }

  &__wrapper {
    display: grid;
    grid-template-rows: auto;
    justify-content: center;
    row-gap: 30px;
  }

  &__item {
    margin: 0 auto;
    max-width: 280px;
    background: $px-card;
    box-shadow: 0 0 30px 3px rgb(0 0 0 / 3%);
  }

  &__image {
    position: relative;
    overflow: hidden;

    &::before {
      z-index: 1;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(206, 167, 111, 0);
      -webkit-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__info {
    padding: 30px;
    text-align: center;
  }

  &__desc {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 300;
    color: $px-main;
    line-height: 22px;
  }

  &__button {
    margin-top: 40px;
    margin-bottom: 0;
    padding-top: 20px;
    border-top: 1px solid $px-yellow;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .special-block {
    &_pd {
      padding-bottom: 70px;
    }
  }
}
