@import '../style/_variable';

.button {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #330f15;
  border: 3px solid $px-yellow;
  padding: 14px;
  max-width: 204px;
  display: block;
  text-decoration: none;
  line-height: 1.15;
  cursor: pointer;
  transition: 1s;

  &_center {
    margin: 0 auto;
  }

  &_start {
    margin-top: 35px;
  }

  &_text-white {
    color: #ffffff;
  }

  &_border-white {
    border-color: #ffffff;
  }

  &_order {
    margin: 40px auto 0 auto;
  }

  &_hover {
    &:hover {
      background-color: $px-yellow;
      transition: 0.7s;
      color: $px-footer;
    }
  }
}

.button_submit {
  font-family: "Playfair Display";
  width: 100%;
  color: #ffffff !important;
  background: $px-main;
  font-weight: 600;
  border: 0;
  padding: 17px 14px;
  text-transform: uppercase;

  &:hover {
    background-color: $px-yellow;
    transition: 0.7s;
    color: $px-footer;
  }

  &:disabled {
    background: $px-grey;
    opacity: 0.8;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

  .button {
    &_order {
      margin-top: 20px;
      width: 100%;
      max-width: 220px;
    }
  }

}