@font-face {
  font-family: 'Playfair Display';
  src: url('../font/Playfair_Display/PlayfairDisplay-Italic-VariableFont_wght.ttf')
      format('ttf'),
    url('../font/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf')
      format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Anjhay';
  src: url('../font/Anjhay/Anjhay.ttf') format('ttf'),
    url('../font/Anjhay/Anjhay.woff') format('woff'),
    url('../font/Anjhay/Anjhay.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}
