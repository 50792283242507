@import '../style/_variable';

.title {
  font-family: 'Playfair Display', serif;
  color: $px-color-title;
  text-align: center;

  &_fs28 {
    font-size: 1.75rem;
    font-weight: 700;
  }

  &_fs14 {
    font-size: 0.875rem;
  }

  &_fs15 {
    font-size: 0.93rem;
    font-weight: 500;
  }

  &_fs36 {
    font-size: 2.25rem;
    margin: 0;
    font-weight: 700;
  }

  &_gold {
    color: $px-yellow;
  }

  &_fs50 {
    font-size: 3.125rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
  }

  &_background {
    background: #e7e3dc;
  }

  &_pd {
    padding: 40px 60px;
  }

  &_white {
    color: #fff;
  }

  &_uppercase {
    text-transform: uppercase;
    font-weight: 700;
  }

  &_align-start {
    text-align: start;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .title {
    &_fs28 {
      font-size: 1.125rem;
    }

    &_fs36 {
      font-size: 1.625rem;
    }

    &_fs50 {
      font-size: 1.625rem;
    }

    &_pd {
      padding: 10px 20px;
    }

    &_custom {
      font-size: 1.375rem;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .title {
    &_fs28 {
      font-size: 1.2rem;
    }

    &_fs36 {
      font-size: 1.315rem;
    }

    &_fs50 {
      font-size: 1.315rem;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .title {
    &_fs36 {
      font-size: 1.205rem;
    }

    &_fs50 {
      font-size: 1rem;
    }
  }
}
