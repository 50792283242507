@import '../../../assets/style/_variable';

.navigation {
  &_pd {
    padding-bottom: 90px;
  }

  &__carousel {
    display: grid;
    grid-template-columns: 90px 1fr 90px;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all .3ms linear;

    &-inner {
      padding-bottom: 40px;
      border-bottom: 1px solid $px-yellow;
      height: 100%;
    }

    &-control {

      &-prev {
        font-size: 58px;
        text-decoration: none;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &-next {
        font-size: 58px;
        text-decoration: none;
      }
    }

    &-indicators {
      padding-top: 20px;
      position: relative;
      z-index: 2;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;

      button {
        margin: 0 8px;
        border: 0;
        cursor: pointer;
        background: #d9d9d9;
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        transition: all 0.3s linear;
        width: 12px;
        height: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        overflow: hidden;
        text-indent: 500px;
        padding: 0;

        &.active {
          background: $px-yellow;
        }
      }
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__item {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 435px;
    display: grid;
    grid-template-columns: 580px 1fr;
    align-items: center;

  }

  &__info {
    padding: 15px 10px 15px 110px;
    width: 100%;
  }

  &__price {
    position: absolute;
    top: calc(100% - 63px);
    left: 0;
    color: #ffffff;
    background: $px-yellow;
    min-width: 180px;
    padding: 14px 24px 10px 24px;
    font-style: italic;
    font-size: 16px;
    font-family: "Playfair Display", Georgia, "Times New Roman", "Microsoft Yahei", serif;
  }
}

.carousel-prev-icon {
  cursor: pointer;
  color: #d9d9d9;
  transition: all 0.3s linear;

  &:hover {
    color: $px-yellow;
  }

}

.carousel-next-icon {
  cursor: pointer;
  color: #d9d9d9;
  transition: all 0.3s linear;

  &:hover {
    color: $px-yellow;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .navigation {
    &_pd {
      padding-bottom: 70px;
    }

    &__carousel {
      grid-template-columns: 100%;

      &-inner {
        width: 100%;
        padding-bottom: 0;
      }


      &-control {

        &-prev {
          display: none;
        }

        &-next {
          display: none;
        }
      }

      &-indicators {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    &__item {
      height: 100%;
      grid-template-columns: 100%;
      justify-items: center;
      position: static;
    }

    &__wrapper {
      position: relative;
    }

    &__info {
      text-align: center;
      padding: 35px 10px;
    }

    &__price {
      top: calc(100% - 256px);
      left: calc(50% - 109px);
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .navigation {
    &__price {
      top: calc(100% - 192px - 80px);
    }
  }

}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

  .navigation {
    &__price {
      top: calc(100% - 192px - 64px);
      left: calc(50% - 90px);
      width: 160px;
      padding: 8px 10px 6px 10px;
      font-size: 12px;
    }
  }
}