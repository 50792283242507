@import '../assets/style/_variable';

.main {
  background-color: $px-background-body;
  color: #4e1720;

  &_mr-top {
    margin-top: 39px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .main {
    &_mr-top {
      margin-top: 60px;
    }
  }
}
