.main {
  &_pd {
    padding: 0 0 100px 0;
  }

  &__content {
    width: 100%;
  }

  &__image {
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

.image {
  width: 100%;
}
