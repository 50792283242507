$px-main: #62121f;
$px-social: #721524;
$px-footer: #4e1720;
$px-error: #df6e6e;
$px-yellow: #c59c5c;
$px-card: rgba(253, 233, 224, 0.4);
$px-color-title: #172236;
$px-grey: #8899a0;
$px-background-body: rgba(253, 233, 224, 0.2);

$px-background-main: #62121f;
$px-background-main-transparent: rgba(98, 18, 31, 0.88);
$px-background-body: rgba(253, 233, 224, 0.2);
