@import '../../../assets/style/_variable';

.block-title {
  text-align: center;
  font-weight: 600;

  &_pb {
    padding: 90px 0 60px 0;
  }
}

.block-description {
  font-family: "Playfair Display", "Times New Roman", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑", serif;
  max-width: 450px;
  font-style: italic;
  font-size: 16px;
  color: $px-grey;

  &_mr {
    margin: 0 auto;
  }
}

.block-delimiter {
  &_mr {
    margin: 20px auto 14px auto;
  }
}

.dot {
  width: 7px;
  height: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: $px-yellow;
  margin: -1px 4px 0 4px;
}

.triangle {
  width: 100px;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  &_right {
    border-left: 107px solid $px-yellow;
  }

  &_left {
    border-right: 107px solid $px-yellow;
  }

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .block-title {

    &_pb {
      padding: 70px 0 30px 0;
    }
  }

  .block-description {
    max-width: 100%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

  .block-title {

    &_pb {
      padding: 45px 0 30px 0;
    }
  }

  .block-description {
    font-size: 14px;
  }

}