.carousels {
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    content: "";
    background: rgba(97, 65, 19, 0.45);
    width: 100%;
    height: 100%;
  }

  &__img {
    height: 600px;
    object-fit: cover;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .carousels {
    &__img {
      height: 370px;
    }

  }

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .carousels {

    &__img {
      height: 470px;
    }

  }
}