@import '../style/variable';

.text {
  font-size: 1rem;

  &_fs13 {
    font-size: 0.8rem;
    font-weight: 600;
  }

  &_fs14 {
    font-size: 0.9rem;
  }

  &_fs15 {
    font-size: 0.9rem;
    line-height: 22px;
    font-weight: 600;
  }

  &_fs22 {
    font-size: 1.375rem;
    font-weight: 700;
    font-family: "Playfair Display"
  }

  &_fs26 {
    font-size: 1.625rem;
    font-weight: 900;
  }

  &_color {
    color: $px-footer;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .text {
    &_fs22 {
      font-size: 1.125rem;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .text {
    &_fs26 {
      font-size: 1.33rem;
    }
  }
}