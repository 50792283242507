@font-face {
  font-family: 'icons';
  src:
    url('../font/fonts/icons.ttf') format('truetype'),
    url('../font/fonts/icons.woff') format('woff'),
    url('../font/fonts/icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reservetion-icon:before {
  content: "\e906";
}

.icon-viber-icon:before {
  content: "\e900";
}

.icon-whatsapp-icon:before {
  content: "\e901";
}

.icon-vk-icon:before {
  content: "\e902";
}

.icon-telegram-icon:before {
  content: "\e903";
}

.icon-instagram-icon:before {
  content: "\e904";
}

.icon-facebook-icon:before {
  content: "\e905";
}