@import './assets/style/font';
@import './assets/style/title';
@import './assets/style/text';
@import './assets/style/button';
@import './assets/style/link';

:root {
  font-size: 1em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Playfair Display', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'PTSerifCaption-Regular', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $px-background-body;
  transition: all 0.5s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: #fff;
}

.container {
  position: relative;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  :root {
    font-size: 0.937em;
  }

  .container {
    margin: 0;
    max-width: 100%;
  }
}
